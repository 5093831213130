<template>
  <div class="contact">
<iframe height="800" title="Embedded Wufoo Form" allowtransparency="true" frameborder="0" scrolling="no" style="width:100%;border:none" src="https://leabuende.wufoo.com/embed/zyqym6r182o5y6/"> <a href="https://leabuende.wufoo.com/forms/zyqym6r182o5y6/">Fill out my Wufoo form!</a> </iframe>  </div>
</template>

<script>

export default {
  name: 'Contact',

}
</script>
<style scoped>
</style>
